import React, { useEffect, useRef, useState } from "react";
import { DropdownButton, Button, Dropdown } from "react-bootstrap";
import "./BotFilterPanel.scss";
import { BOTS_ACTIVE_TAB, BOTS_ORDER_BY, BOTS_SORT } from "../../actions/types";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
//import { TimeFrames, Frequencies, BaseCoins } from "../../../Const";



const RentalFilterPanel = (props) => {
	const dispatch = useDispatch()
	const {t} = useTranslation()
	
	const setOrderBy = (sort)=>{
	dispatch({
		type:BOTS_ORDER_BY,
		payload:sort
	})
	}
    const orderBy = useSelector((state) => state.pagination.botsOrderBy) || "desc";
	
	const dropdownRef = useRef(null)
	const [showFilterMenu,setShowFilterMenu] = useState(false)
	const handleClickOutside = (event) => {
		// Check if the click is outside of any bot's menu or button
		if (!dropdownRef.current || dropdownRef.current.contains(event.target)) {
		  return;
		}
		else{
			setShowFilterMenu(false)
		}
	  };
	  useEffect(() => {
		document.addEventListener('mousedown', handleClickOutside);
		return () => {
		  document.removeEventListener('mousedown', handleClickOutside);
		};
	  }, []);
  
  return (
    <>
	<label>
		<input type="text" placeholder="Search" onChange={(e)=>props.setSearchBotName(e.target.value)} value={props.searchBotName}/>
		<div className="dropdown d-flex gap10" ref={dropdownRef}>
			
			<button className="dropdown-toggle"   onClick={()=>setOrderBy(orderBy=="asc"?"desc":"asc")}>
			<img src={`images/${orderBy}.svg`} alt=""/>
				
			</button>
		</div>
	</label>
</>
  );
};

export default RentalFilterPanel;
