import React, { useState, useEffect } from "react";
import { Card, Button, Row, Col, Table } from "react-bootstrap";
import { connect, useSelector } from "react-redux";
import { SUB_METHOD, SUB_STATUS, SUB_TIER, SUB_TIER_NAME, SUB_TYPE } from "../../Const";
import PremiumInfo from "../subscription/PremiumInfo";
import FreeInfo from "../subscription/FreeInfo";
import { checkSub, myCurrentPlan } from "../../utils/SubUtils";
import { capitalize, formatDate, milisecOf } from "../../utils/Utils";
import Switch from "react-switch";
import CustomPagination from "../common/CustomPagination";
import Loading from "../common/Loading";
import useLoading from "../hooks/useLoading";
import { toast } from "react-toastify";
import "./SubscriptionPanel.scss";

import {
  checkSubscription,
  cancelSubscription,
  continueSubscription,
} from "../../actions/setting-action";
import ConfirmCancelDialog from "./ConfirmCancelDialog";
import ContinueSubscriptionDialog from "./ContinueSubscriptionDialog";
import { useTranslation } from "react-i18next";

const SubscriptionPanel = (props) => {
  const { checkSubscription, cancelSubscription, continueSubscription } = props;
  const {t} = useTranslation()
  const [showCancelDialog, setShowCancelDialog] = useState(false);
  const [showContinueDialog, setShowContinueDialog] = useState(false);
  const [isYearly, setIsYearly] = useState(true);
  const [subscriptionType, setSubscriptionType] = useState("yearly");
  const [startItem, setStartItem] = useState(0);
  const [setLoading, bindLoading] = useLoading(false);
  const [plan, setPlan] = useState("yearly");
  const itemPerPage = 5;
  const subscription = useSelector(
    (state) => state.auth.user.user_subscription
  );
  const {
    subMethod,
    subType,
    subPremium,
    subStatus,
    subEndDate,
    subInvoices,
    subDayLeft,
    subTier,
  } = checkSub(subscription);

  const showCancelBtn =
    subPremium &&
    [SUB_METHOD.stripe].includes(subMethod) &&
    subStatus == SUB_STATUS.active;

  subInvoices.sort((item1, item2) => {
    const date1 = item1.time;
    const date2 = item2.time;
    return milisecOf(date2) - milisecOf(date1);
  });

  const clickCancel = () => {
    setShowCancelDialog(false);
    setLoading(true);
    cancelSubscription()
      .then(() => {
        setLoading(false);
        toast.info(t("plan-cancelled"));
      })
      .catch((err) => {
        setLoading(false);
        toast.error(String(err));
      });
  };

  const clickContinue = () => {
    if (subStatus != SUB_STATUS.canceled) return;
    setShowContinueDialog(false);
    setLoading(true);
    continueSubscription()
      .then(() => {
        setLoading(false);
        toast.info(t("plan-continued"));
      })
      .catch((err) => {
        setLoading(false);
        toast.error(String(err));
      });
  };
  const changeSubscriptionType = (plan)=>{
    setSubscriptionType(plan)
  }

  useEffect(() => {
    checkSubscription().then((res) => {});
  }, [checkSubscription]);

  useEffect(() => {
    if (subPremium) {
      setIsYearly(subType == SUB_TYPE.yearly);
    }
  }, [subPremium]);
  const changeStartItem= (electedExchangeId,itemsCountPerPage,num)=>{
    setStartItem(num)
  }
  const txs =
    subInvoices &&
    subInvoices.slice(startItem, startItem + itemPerPage).map((item, index) => {
      return (
        <tr key={index}>
          <td>{formatDate(item.time)}</td>
          <td>{item.txId}</td>
          <td>{item.amount}</td>
        </tr>
      );
    });
    const [activeTab, setActiveTab] = useState(2);
    const changeTab = (index,tab) => {
      setActiveTab(index);
      setPlan(plans[index])
    };
    const tabTitles = [t("Monthly"), t("Quarterly"),t("yearly")];
    const plans = ["monthly","quarterly","yearly"];
    const tabHeaders = tabTitles.map((item, index) => {
      return (
          <li onClick={() => changeTab(index)} key={index}><a href="#" className={(index == activeTab ? "spb-active" : "" )}>{item}</a></li>
      );
    });

  return (
    <>
    <div className="col-sm-10 setting-right-col">
        <div className="settings-subscription-area">
            <div className="settings-subscription-header">
              <div className="dashboard-newuser-area">
                  <div className="newuser-earning-area">
                    <div className="newuser-cash-content">
                        <button><img src="/images/current-plan.svg" alt="cash"/></button>
                        <div>
                          <span>My Current Plan</span>
                          <h5>{myCurrentPlan(subscription) }</h5>
                        </div>
                    </div>
                    <div className="newuser-cash-content">
                        <button><img src="/images/calender-icon22.svg" alt="cash"/></button>
                        <div>
                          <span>Days Remaining</span>
                          <h5>{(subTier=="tier1" || subTier=="tier2") && subPremium?"Lifetime":subDayLeft}</h5>
                        </div>
                    </div>
                    <div className="newuser-cash-content">
                        <button><img src="/images/payment-card.svg" alt="cash"/></button>
                        <div>
                          <span>Next Payment</span>
                          <h5>{subStatus == SUB_STATUS.canceled
                                        ? t("cancelled")
                                        : subEndDate} {showCancelBtn && <span className="cancel-btn" onClick={() => setShowCancelDialog(true)}>{t("cancel-renewal")}</span>}</h5>
                            
                        </div>
                    </div>
                  </div>
              </div>
            </div>
            <div className="payment-details-btn">
              <a href="https://billing.stripe.com/p/login/9AQ00C81bd6EcF27ss" target="_blank"><button>Manage Payment Details</button></a>
            </div>
            <div className="subs-plan-area">
              <div className="subs-plan-header">
                  <h4>Subscription Plans</h4>
                  <ul>
                    {tabHeaders}
                  </ul>
              </div>
              <div className="subs-plan-item">
                  <div className="row g-0">
                      {/* <FreeInfo isPremium={subPremium} t={t}  tier={SUB_TIER.free}
                      subTier={subTier} plan={plan}
                      className={'spcc-1'}/> */}
                      <PremiumInfo
                      isYearly={isYearly}
                      subStatus={subStatus}
                      subPremium={subPremium}
                      tier={SUB_TIER.premium}
                      subTier={subTier}
                      plan={plan}
                      onUpgrade={() => setShowContinueDialog(true)}
                      className={'spcc-2'}
                      t={t}
                    />
                    <PremiumInfo
                      isYearly={isYearly}
                      subStatus={subStatus}
                      subPremium={subPremium}
                      tier={SUB_TIER.pro}
                      subTier={subTier}
                      plan={plan}
                      onUpgrade={() => setShowContinueDialog(true)}
                      t={t}
                      className={'spcc-1 spcc-3'}
                    />
                    <PremiumInfo
                      isYearly={isYearly}
                      subStatus={subStatus}
                      subPremium={subPremium}
                      tier={SUB_TIER.master}
                      subTier={subTier}
                      plan={plan}
                      onUpgrade={() => setShowContinueDialog(true)}
                      t={t}
                      className={'spcc-2 spcc-4'}
                    />
                    <div className="col-12 ">
                            <div className="sp-cart-footer">
                              <a href="https://www.cryptohero.ai/pricing" target="_blank">{t("promo-text")}</a>
                            </div>
                        </div>
              
              </div>
              </div>
              {txs != null && txs.length > 0 ? (
              <div className="pd-trade-history-area subs-history-table-area ">
                  <div className="pd-th-header-content">
                    <div className="row">
                        <div className="col-sm-6">
                          <div className="pd-th-header-left">
                              <h4>{t("payment-history")}</h4>
                          </div>
                        </div>
                    </div>
                  </div>
                  <div className="pd-th-table-area">
                    <div className="col-lg-12">
                        <div className="pd-th-table-content">
                          <div className="open-table-content otc">
                              <table className="table">
                                <tr>
                                    <th>{t("date")}</th>
                                    <th>Transaction ID</th>
                                    <th>{t("price")}</th>
                                </tr>
                                <tbody>{txs}</tbody>
                              </table>
                          </div>
                        </div>
                    </div>
                  </div>
              </div>
            ):<>
            <br></br>
            <br></br>
            <br></br>
            </>}
            </div>
      </div>
   </div>
      <ConfirmCancelDialog
        show={showCancelDialog}
        onHide={() => setShowCancelDialog(false)}
        confirmed={() => clickCancel()}
      />
      <ContinueSubscriptionDialog
        show={showContinueDialog}
        onHide={() => setShowContinueDialog(false)}
        confirmed={() => clickContinue()}
      />
      <Loading {...bindLoading} />
    </>
  );
};

export default connect(null, {
  checkSubscription,
  cancelSubscription,
  continueSubscription,
})(SubscriptionPanel);
