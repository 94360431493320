import { NOTIFICATION_POPUP } from "./actions/types";

export const TimeFrames = ["1W", "1M", "3M", "6M", "1Y"];
export const TimeFrameValues = [7, 30, 90, 180, 365];
export const Frequencies = ["15m", "1h", "2h", "4h", "1D"];
export const FrequenciesSimple = ["15m", "1h", "4h", "1D"];
export const FrequenciesPro = ["5m","15m", "1h", "4h", "1D"];
export const FrequencyValues = [15, 60, 120, 240, 1440];
export const FrequencyValuesSimple = [15, 60, 240, 1440];
export const FrequencyValuesPro = [5,15, 60, 240, 1440];
export const FrequenciesProDca = ["5m","15m", "1h", "2h", "4h", "1D"];
export const FrequencyValuesProDca = [5,15, 60, 120, 240, 1440];
export const BaseCoins = ["ALL","USD", "BTC", "ETH"];



export const TimeWindow = {
  "1D": 1,
  "1W": 7,
  "2W": 14,
  "4W": 28,
  "5W": 35,
  "6W": 42,
  "1M": 30,
  "2M": 60,
  "3M": 90,
  "6M": 180,
  "1Y": 365,
};
export const AllFrequencies = {
  1:"1m",
  5:"5m",
  15:"15m",
  60:"1h",
  120:"2h",
  240:"4h",
  1440:"1D"
}
export const Period = {
  "1D": "one Day",
  "3D": "3 Days",
  "1W": "one Week",
  "1M": "one month",
  "3M": "3 Months",
  "6M": "6 Months",
  "2M": "2 Months",
  "6M": "6 Months",
  "1Y": "One Year",
};

export const DealStatus = {
  waiting: "waiting",
  inProgress: "in_progress",
  exiting: "exiting",
  exited: "exited",
  takingProfit: "taking_profit",
  takeProfit: "take_profit",
  stoppingLoss: "stopping_loss",
  stopLoss: "stop_loss",
  liquidating: "liquidating",
  liquidated: "liquidated",
  error: "error",
};

export const IncompletedDeals = [
  DealStatus.inProgress,
  DealStatus.exiting,
  DealStatus.takingProfit,
  DealStatus.stoppingLoss,
  DealStatus.waiting,
  DealStatus.liquidating,
];

export const SUB_METHOD = {
  apple: "apple",
  google: "google",
  stripe: "stripe",
  paypal: "paypal",
};

export const SUB_TYPE = {
  monthly: "monthly",
  yearly: "yearly",
  quarterly: "quarterly",
};
export const SUB_TYPE_NAME = {
  monthly: "monthly",
  yearly: "yearly",
  quarterly: "quarterly",
};

export const SUB_TIER = {
  free: "free",
  premium: "premium",
  pro: "pro",
  master:"master",
  tier1:"tier1",
  tier2:"tier2"
};
export const SUB_TIER_NAME = {
  free: "Basic",
  premium: "Premium",
  pro: "Professional",
  master:"Master",
  tier1:"Tier 1",
  tier2:"Tier 2"
};
export const PLAN_NAME = {
  free: "Basic",
  premium: "Premium",
  pro: "Professional",
  master:"Master",
  tier1:"Appsumo Tier 1",
  tier2:"Appsumo Tier 2"
};
export const PLAN_TYPE = {
  monthly: "Monthly",
  quarterly: "Quarterly",
  yearly: "Yearly",
};
export const SUBSCRIPTION_PLAN = {
  yearly: {premium:"199.99",pro:"399.99",master:"999.99"},
  monthly: {premium:"19.99",pro:"39.99",master:"99.99"},
  quarterly: {premium:"58.99",pro:"109.99",master:"289.99"}
};
export const CJ_TYPE = {
  premium: 432834,
  pro: 432835,
  master: 432835
};

export const SUB_STATUS = {
  active: "active",
  canceled: "canceled",
};

export const Exchanges = {
  binance: 1,
  huobi: 2,
  okex: 3,
  bittrex: 5,
};

export const AccountType = {
  spot: "spot",
  isolated: "isolated",
  cross: "cross",
};

export const AccountName = {
  spot: "SPOT",
  isolated: "ISOLATED",
  cross: "CROSS",
};

export const OrderType = {
  limit: "limit",
  market: "market",
  stoplossLimit: "stop_loss_limit",
};

export const OrderName = {
  limit: "Limit",
  market: "Market",
  stoplossLimit: "Stoploss Limit",
};

export const SideEffectType = {
  normal: "NO_SIDE_EFFECT",
  borrow: "MARGIN_BUY",
  repay: "AUTO_REPAY",
};

export const SideEffectName = {
  normal: "Normal",
  borrow: "Borrow",
  repay: "Repay",
};

export const TradeSide = {
  buy: "buy",
  sell: "sell",
};

export const ExchangeStatus = {
  ok: "ok",
  error: "error",
  shutdown: "shutdown",
  maintenance: "maintenance",
};

export const ExchangeStatusName = {
  ok: "Fully Operational",
  error: "Exchange shutdown occured",
  shutdown: "Unexpected Error has occured",
  maintenance: "Undergoing scheduled maintainance",
};

export const UserRole = {
  alpha: "alpha",
  beta: "beta",
  normal: "normal",
};

export const ScreenSize = {
  small: "small",
  medium: "medium",
  large: "large",
  xlarge: "xlarge",
};

export const PostStatus = {
  verified: "verified",
  unverified: "unverified",
};

export const ReferType = {
  TYPE_POINT_SENT_MAIL: "point_sent_mail",
  TYPE_POINT_REFERRAL_SIGNUP: "point_signed_up_referral",
  TYPE_POINT_SIGNED_UP: "point_signed_up",
  TYPE_POINT_REFERRAL_SUBSCRIBE: "point_subscribed_referral",
  TYPE_POINT_SUBSCRIBED: "point_subscribed",
  TYPE_POINT_SOCIAL: "point_social",
  TYPE_GIFT_REFERRAL: "gift_referral",
  TYPE_GIFT_REFEREE: "gift_referee",
  TYPE_TIER_ONE: "tier_one",
  TYPE_TIER_TWO: "tier_two",
  TYPE_TIER_THREE: "tier_three",
  TYPE_TIER_FOUR: "tier_four",
};

export const RedeemMethod = {
  paypal: "paypal",
  usdtErc20: "usdt-erc20",
  usdtTrc20: "usdt-trc20",
};

export const RedeemMethodName = {
  paypal: "Paypal",
  usdtErc20: "USDT - ERC20",
  usdtTrc20: "USDT - TRC20",
};

export const StoplossType = {
  absolute: "absolute",
  fixedTsl: "fix",
  variableTsl: "variable",
};
export const TakeProfitType = {
  absolute: "absolute",
  fixedTP: "fix",
  variableTP: "variable",
};

export const StoplossTypeName = {
  absolute: "Absolute",
  fixedTsl: "Fixed Trailing Stoploss",
  variableTsl: "Variable Trailing Stoploss",
};
export const TakeProfitTypeName = {
  absolute: "Absolute Take Profit",
  fixedTP: "Fixed Trailing Take Profit",
  variableTP: "Variable Trailing Take Profit",
};

export const PaperExchange = {
  id: 0,
  name: "Paper",
  image: "https://web.cryptohero.ai/storage/exchanges/paper.png",
};

export const PaperBtcUsdt = {
  id: 2771,
  exchange_id: 0,
  pair_id: 11,
  name: "BTCUSDT",
  price_precision: 2,
  amount_precision: 6,
  step: 0.000001,
  pair: {
    id: 11,
    from: "BTC",
    to: "USDT",
    name: "BTC-USDT",
    kline_exchange_id: 1,
    quote_image: "https://web.cryptohero.ai/storage/coins/USDT.png",
    base_image: "https://web.cryptohero.ai/storage/coins/BTC.png",
  },
};

export const CreateBotModalType = {
  notShow: "notShow",
  newBot: "newBot",
  presetConfig: "presetConfig",
  editBot: "editBot",
};
export const ZeroAmount = "00000000";

export const BotType = {
  simple: "simple",
  advance: "advance",
  dca: "dca",
  exit: "exit",
  price: "price",
  grid: "grid",
  sell: "sell",
  inter_arbitrage: "inter_arbitrage",
  intra_arbitrage: "intra_arbitrage",
};

export const BotTypeName = {
  simple: "Simple",
  advance: "Advanced",
  dca: "DCA",
  exit: "Exit",
  price: "Price",
  grid: "Grid",
  sell: "Sell",
  inter_arbitrage: "Inter Arbitrage(Beta)",
  intra_arbitrage: "Intra Arbitrage(Beta)",
};
export const StrategyModule = {
  simple: true,
  advance: true,
  dca: false,
  exit: true,
  price: true,
  grid: false,
  sell: false,
};
export const CreateBotModules = {
  FUND_ALLOCATION:{
    simple: true,
    advance: true,
    dca: true,
    exit: true,
    price: true,
    grid: true,
    sell: false,
  }
}
export const DcaType = {
  time: "time",
  indicator: "indicator",
  buyDown: "buy_down",
  sellUp: "sell_up",
};
export const GridEntryTypes = {
  immediate: "imd",
  indicator: "osc",
  range: "range",
};
export const GridEntryTypeNames = {
  immediate: "Immediate",
  indicator: "Indicator",
  range: "Range",
};

export const TradeListStatuses = {
  '': "All",
  ongoing: "Ongoing",
  filled: "Filled",
  cancelled: "Cancelled",
  partial_filled: "Partially Filled",
};
export const TradeListSides = {
  '': "Both",
  BUY: "Buy",
  SELL: "Sell"
};

export const TradeOrders = {
    0:"All",
    1:"Base",
    2:"Extra",
    3:"Take Profit",
    4:"Liquidated",
    5:"Indicator Exit",
    6:"Stoploss"
}
export const TradeOrderNames = {
    0:"All",
    1:"Base",
    2:"Extra",
    3:"Take Profit",
    4:"Liquidated",
    5:"Indicator Exit",
    6:"Stoploss"
}

export const DcaTypePercentage = "percentage";

export const DcaTypeName = {
  time: "Time",
  indicator: "Indicator",
  buyDown: "Buy Down",
  sellUp: "Sell Up",
};

export const DcaTime = {
  daily: "daily",
  weekly: "weekly",
  monthly: "monthly",
};

export const WeekTime = {
  mon: "0",
  tue: "1",
  wed: "2",
  thu: "3",
  fri: "4",
  sat: "5",
  sun: "6",
};

export const WeekTimeName = {
  mon: "Monday",
  tue: "Tuesday",
  wed: "Wednesday",
  thu: "Thursday",
  fri: "Friday",
  sat: "Saturday",
  sun: "Sunday",
};

export const IndicatorType = {
  BB: 1,
  EMA: 2,
  SRSI: 3,
  RSI: 4,
  MACD: 5,
  VOL: 6,
};
export const Themes = {
  light: "light",
  dark: "dark",
  pink: "pink",
  purple: "purple",
  green: "green",
};
export const ChartColors = {
  'light':{1:'#73CEE2',2:'#FA5252'},
  'dark':{1:'#73CEE2',2:'#FA5252'},
  'pink':{1:'#E7444F',2:'#FA5252'},
  'purple':{1:'#9461EA',2:'#FA5252'},
  'green':{1:'#2CA6FF',2:'#FA5252'},
  
}
export const PortfolioColors = {
  'light':{1:'#73CEE2',2:'#05cd99'},
  'dark':{1:'#73CEE2',2:'#05cd99'},
  'pink':{1:'#E7444F',2:'#05cd99'},
  'purple':{1:'#9461EA',2:'#05cd99'},
  'green':{1:'#73CEE2',2:'#05cd99'}
}
export const CryptoAssetColors = {
  light:["#9AF5F6","#EF8868","#F8D050","#6467F2","#5F9DF7","#DC3D35"],
  dark:["#9AF5F6","#EF8868","#F8D050","#6467F2","#5F9DF7","#DC3D35"],
  pink:["#9AF5F6","#EF8868","#F8D050","#6467F2","#5F9DF7","#DC3D35"],
  purple:["#9AF5F6","#EF8868","#F8D050","#6467F2","#5F9DF7","#DC3D35"],
  green:["#9AF5F6","#EF8868","#F8D050","#6467F2","#5F9DF7","#DC3D35"]
}
export const TokoRefCode = "C0EK4I";
export const BitgetRefCode = "DBBN88";
export const MaxBotPerRent = 2;

export const UsdtQRcode = {
  'erc':'/images/usdt_wallet_new.png',
  'bep':'/images/usdt_wallet_new.png',
  'trc':'/images/usdt_wallet_trc_new.png',
  'ERC20':'/images/usdt_wallet_new.png',
  'BEP20':'/images/usdt_wallet_new.png',
  'TRC20':'/images/usdt_wallet_trc_new.png',
}
export const usdtAddress = {
  'erc':'0x3b04c17b07e87f9fb8939de7f9f3e5770689ea9d',
  'bep':'0x3b04c17b07e87f9fb8939de7f9f3e5770689ea9d',
  'trc':'TRCNQA6P3yxpNGb7o4nPkoX8ztNT5F1dqs',
  'ERC20':'0x3b04c17b07e87f9fb8939de7f9f3e5770689ea9d',
  'BEP20':'0x3b04c17b07e87f9fb8939de7f9f3e5770689ea9d',
  'TRC20':'TRCNQA6P3yxpNGb7o4nPkoX8ztNT5F1dqs',
}
export const PremiumSubcriptionAmount = {
  'monthly':'13.99',
  'yearly':'139.99',
}
export const ProfessionalSubcriptionAmount = {
  'monthly':'29.99',
  'yearly':'299.99',
}
export const RentSubscriptions = {
  'monthly':'monthly_fee',
  'quarterly':'quarterly_fee',
  'yearly':'yearly_fee',
}
export const RentPlans = {
  'monthly':'monthly',
  'yearly':'yearly',
  'quarterly':'quarterly'
}
export const TemplateStatus = {
  'approved':'Approved',
  'submitted':'Submitted',
  'renting':'Renting',
  'rejected':'Rejected'
}
export const TemplateImageUrl = "https://cryptohero.s3.ap-southeast-1.amazonaws.com/public/templates";

export var TradingViewTimeZones = [
  { offset: '-10:00', label: '(UTC-10) Honolulu', tzCode: 'Pacific/Honolulu' },
  { offset: '-08:00', label: '(GMT-08:00) Pacific Time', tzCode: 'America/Juneau' },
  { offset: '-07:00', label: '(GMT-07:00) Mountain Time', tzCode: 'America/Los_Angeles' },
  { offset: '-06:00', label: '(GMT-07:00) Mountain Time', tzCode: 'America/Mexico_City' },
  { offset: '-05:00', label: '(GMT-05:00) Eastern Time', tzCode: 'America/Bogota' },
  { offset: '-04:00', label: '(GMT-04:00) Atlantic Time - Halifax', tzCode: 'America/Caracas' },
  { offset: '-03:00', label: '(GMT-03:00) Buenos Aires', tzCode: 'America/Santiago' },
  { offset: '-02:00', label: '(GMT-02:00) Sao Paulo', tzCode: 'America/Sao_Paulo' },
  { offset: '-01:00', label: '(GMT-01:00) Azores', tzCode: 'Atlantic/Azores' },
  { offset: '+00:00', label: '(GMT+00:00) London', tzCode: 'Atlantic/Reykjavik' },
  { offset: '+01:00', label: '(GMT+01:00) Berlin', tzCode: 'Africa/Lagos' },
  { offset: '+02:00', label: '(GMT+02:00) Helsinki', tzCode: 'Europe/Belgrade' },
  { offset: '+03:00', label: '(GMT+03:00) Istanbul', tzCode: 'Asia/Bahrain' },
  { offset: '+04:00', label: '(GMT+04:00) Dubai', tzCode: 'Asia/Dubai' },
  { offset: '+04:30', label: '(GMT+04:30) Kabul', tzCode: 'Asia/Tehran' },
  { offset: '+05:00', label: '(GMT+05:00) Maldives', tzCode: 'Asia/Ashkhabad' },
  { offset: '+05:30', label: '(GMT+05:30) India Standard Time', tzCode: 'Asia/Kolkata' },
  { offset: '+05:45', label: '(GMT+05:45) Kathmandu', tzCode: 'Asia/Kathmandu' },
  { offset: '+06:00', label: '(GMT+06:00) Dhaka', tzCode: 'Asia/Almaty' },
  { offset: '+06:30', label: '(GMT+06:30) Cocos', tzCode: 'Indian/Cocos' },
  { offset: '+07:00', label: '(GMT+07:00) Bangkok', tzCode: 'Asia/Bangkok' },
  { offset: '+08:00', label: '(GMT+08:00) Hong Kong', tzCode: 'Asia/Singapore' },
  { offset: '+08:30', label: '(GMT+08:30) Pyongyang', tzCode: 'Asia/Singapore' },
  { offset: '+09:00', label: '(GMT+09:00) Tokyo', tzCode: 'Asia/Seoul' },
  { offset: '+09:30', label: '(GMT+09:30) Central Time - Darwin', tzCode: 'Australia/Brisbane' },
  { offset: '+10:00', label: '(GMT+10:00) Eastern Time - Brisbane', tzCode: 'Australia/Brisbane' },
  { offset: '+10:30', label: '(GMT+10:30) Central Time - Adelaide', tzCode: 'Australia/Adelaide' },
  { offset: '+11:00', label: '(GMT+11:00) Eastern Time - Melbourne, Sydney', tzCode: 'Australia/Sydney' },
  { offset: '+12:00', label: '(GMT+12:00) Nauru', tzCode: 'Pacific/Nauru' },
  { offset: '+13:00', label: '(GMT+13:00) Auckland', tzCode: 'Pacific/Auckland' },
  { offset: '+14:00', label: '(GMT+14:00) Kiritimati', tzCode: 'Pacific/Chatham' }
];

export const TourClassess = {
  dashboard:".dashboard-tour-paper",
  portfolio_value:".portfolio-value-tour",
  bot_info:".bots-tour",
  create_bot_btn:".create-bot-btn-tour",
  bot_title_info:".create-bot-name-tour",
  asset_info:".bot-asset-tour",
  trade_params:".trade-params-tour",
  entry_conditions:".entry-conditions-tour",
  exit_conditions:".exit-conditions-tour",
  backtest:".backtest-tour",
  create_bot_submit:".create-bot-submit-tour",
  active_bots:".active-bots-tour",
  inactive_bots: ".inactive-bots-tour",
  rented_bots:".rented-bots-tour",
  deals:".deals-page-tour",
  open_deals:".open-deals-tour",
  closed_deals:".completed-deals-tour",
  trade_logs:".trade-logs-tour",
  portfolio:".portfolio-tour",
  marketplace:".marketplace-tour",
  terminal:".terminal-tour",
  price_alert:".price-alert-tour"
}
export const LanguageName = {
  en:"English",
  es:"Spanish",
  id:"Bahasa Indonesia"
}
export const TPTypeName = {
  absolute: "Absolute Take Profit",
  fix: "Fixed Trailing Take Profit",
  variable: "Variable Trailing Take Profit",
};
export const userSettings = {
  notification_popup: NOTIFICATION_POPUP,
};