import React, { useState, useEffect } from "react";
import { connect, useSelector } from "react-redux";
import { useHistory, Redirect } from "react-router-dom";
import { verifyOtp, getRegisterOtp, signOut } from "../../actions/auth-action";
import { Container, Row, Col, Form, Button, Card } from "react-bootstrap";
import Header from "../common/Header";
import Footer from "../common/Footer";
import Loading from "../common/Loading";
import useLoading from "../hooks/useLoading";
import useInput from "../hooks/useInput";
import { toast } from "react-toastify";
import * as Analytic from "../../utils/Analytic";
import "./VerifyOtp.scss";
import ContentContainer from "../common/ContentContainer";
import GuestContainer from "./GuestContainer";

const VerifyOtp = (props) => {
  const { verifyOtp, getRegisterOtp, signOut } = props;

  const [code, bindCode] = useInput("");
  const [setLoading, bindLoading] = useLoading();
  const [timer, setTimer] = useState(60);
  useEffect(() => {
    let interval;

    if (timer > 0) {
      interval = setInterval(() => {
        setTimer((prevTimer) => prevTimer - 1);
      }, 1000);
    }

    return () => clearInterval(interval);
  }, [timer]);

  const token = useSelector((state) => state.auth.token);

  const history = useHistory();

  if (!token) {
    return <Redirect to="/login" />;
  }

  const clickVerify = (e) => {
    e.preventDefault();
    setLoading(true);
    verifyOtp(code)
      .then(() => {
        setLoading(false);
        Analytic.sendEvent(Analytic.signUpSuccess);
        history.push("/signup/success");
      })
      .catch((err) => {
        setLoading(false);
        toast.error(err);
      });
  };

  const clickResend = (e) => {
    e.preventDefault();
    setTimer(60);
    setLoading(true);
    getRegisterOtp().then(() => {
      setLoading(false);
      toast.info("New code has been sent.");
    });
  };

  const logout = () => {
    signOut();
  };

  return (
    <>
      <GuestContainer>
      <div className="login-content-wrapper signup-content-wrapper">
          <div className="login-content signup-content">
        
        
          <Col className="verify-otp-col">
            <div className="p-4">
              <h6 className="header-text text-center mb-3 top-margin">
                Verify OTP
              </h6>
              <p className="top-margin">
                Check your email inbox to get OTP code (it might be in the spam
                box).
              </p>
              <p className="top-margin">
              Please email us at <a href="mailto:admin@cryptohero.ai">admin@cryptohero.ai</a> if you do not receive the message after multiple attempts.
              </p>
              <Form className="mt-3">
                <Form.Group className="top-margin ch-form">
                  <Form.Control placeholder="Code" {...bindCode} autoFocus="true" className="input-text"/>
                </Form.Group>
                <br></br>
                <br></br>
                <Button
                  type="submit"
                  className="blue-btn w-100"
                  onClick={clickVerify}
                >
                  SUBMIT
                </Button>
                <Row className="justify-content-center w-100 top-margin mt-3 mb-4">
                  {timer > 0 ?<><a className="disabled-link">
                  RESEND OTP {timer > 0 && `in ${timer} seconds`}
                  </a></>:<><a className="link pointer" onClick={clickResend} disabled={timer > 0}>
                  RESEND OTP 
                  </a></>}
                  
                </Row>
                <Row className="justify-content-center w-100 top-margin">
                  <a href="" onClick={logout}>
                    Use another account?
                  </a>
                </Row>
               
              </Form>
            </div>
          </Col>
          </div>
          
       
        </div>
        <Loading {...bindLoading} />
        <img height="1" width="1" style={{display:"none"}} src="https://q.quora.com/_/ad/937dcd12503f450b947fd06c846f9cdd/pixel?tag=ViewContent"/>
        <img height="1" width="1" style={{display:"none"}} src="https://q.quora.com/_/ad/937dcd12503f450b947fd06c846f9cdd/pixel?tag=CompleteRegistration"/>
      </GuestContainer>
    </>
  );
};
export default connect(null, { verifyOtp, getRegisterOtp, signOut })(VerifyOtp);
