import React, { useState, useCallback, useEffect } from "react";
import { toggleNav } from "../../actions/setting-action";
import { connect, useDispatch, useSelector } from "react-redux";
import "./ContentContainer.scss";
import ContactForm from "../help/ContactForm";
import Header from "./Header";
import Loading from "./Loading";
//import TawkMessengerReact from '@tawk.to/tawk-messenger-react';
import SideMenu from "./SideMenu";
import OffCanvasMenu from "./OffCanvasMenu";
import NotificationPopup from "./NotificationPopup";


const ContentContainer = (props) => {
  const navExpand = useSelector((state) => state.settings.navExpand);
  const takeTour = useSelector((state) => state.settings.takeTour);
  const loading = useSelector((state) => state.settings.loading);
  const startTakeTour = useSelector((state) => state.settings.startTakeTour);
  const newUserIntroPupup = useSelector((state) => state.settings.newUserIntroPupup);
  
  const [showContact, setShowContact] = useState(false);
  const [showTour, setShowTour] = useState(true);
  const toggleExpand = () => props.toggleNav(!navExpand);
  return (
    <>
    <OffCanvasMenu url={props.url}/>
    <div className="row g-0">
      <SideMenu url={props.url}></SideMenu>
      {/* <Header
            url={props.url}
            toggleExpand={toggleExpand}
            showContact={() => setShowContact(true)}
          /> */}
      <div className="col-lg-10 main-right-col">
        <div className="main-right-area">
          <div className="main-right-header">
							<div className="row g-0 align-items-center">
              <Header
                  url={props.url}
                  showBack={props.showBack}
                  toggleExpand={toggleExpand}
                  showContact={() => setShowContact(true)}
                />
              
              </div>
          </div>
          <div className="main-right-content">
              {props.children}
							
          </div>
      </div>
     
     </div>
     </div>
      <ContactForm show={showContact} onHide={() => setShowContact(false)} />
      {/* <NotificationPopup></NotificationPopup> */}
      {/* { props.showChat ?
      <TawkMessengerReact
                widgetId="1ho9adh0i"
                propertyId="64e04096cc26a871b030205c"/>
                :<></>} */}
      {/* {(newUserIntroPupup && !startTakeTour) ? <NewUserTour setShowTour={setShowTour}></NewUserTour>:<></> } */}
      </>
  );
};

export default connect(null, { toggleNav })(ContentContainer);
