import React from "react";
import { Button } from "react-bootstrap";
import "./FreeInfo.scss";

const PremiumPlan = (props) => {
  const { isPremium,t } = props;
  return (
    <ul>
        {/* <li>
            <img src="/images/sp-icon.svg" alt=""/>
            <span>{t("free-info-1")}</span>
        </li> */}
        <li>
            <img src="/images/sp-icon.svg" alt=""/>
            <span>15 {t("active-bots")}</span>
        </li>
        <li>
        <img src="/images/sp-icon.svg" alt=""/>
        <span>{t("premium-info-1")}</span>
        </li>
        <li>
        <img src="/images/sp-icon.svg" alt=""/>
        <span>5 {t("price-alerts")}</span>
        
        </li>
       
    </ul>
  );
};

export default PremiumPlan;
