import React, { useState, useEffect, useRef } from "react";
import { Badge, Button, Modal, OverlayTrigger, Table, Tooltip } from "react-bootstrap";
import Switch from "react-switch";
import { connect,useDispatch,useSelector } from "react-redux";
import { activeBot, botFeedback, deleteBot, getRentBotDetail } from "../../actions/bot-action";
import NoData from "../common/NoData";
import "./BotTab.scss";
import { capitalize, currencyFormat, decimalOf, isLong } from "../../utils/Utils";
import { toast } from "react-toastify";
import { t } from "i18next";
import { Link } from "react-router-dom/cjs/react-router-dom";
import useLoading from "../hooks/useLoading";
import Loading from "../common/Loading";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import { resetRentBotConfig } from "../../actions/create-bot-action";
import { CREATE_BOT_START, SET_RENT_BOT_SELECTED } from "../../actions/create-bot-type";
import RentBotViewDetail from "../marketplace/bots/RentBotViewDetail";
import { useTranslation } from "react-i18next";
import { cancelRental } from "../../actions/rent-bot-action";
import CancelRentalConfirm from "../marketplace/bots/CancelRentalConfirm";

const RentCard = (props) => {
  
  const { rental,istartItem,getRentBotDetail,selectedExchangeId,itemsPerPage,pageNum,botFeedback,resetRentBotConfig,cancelRental} = props;
  const {t} = useTranslation()
  const [setLoading, bindLoading] = useLoading(false);
  const theme =  useSelector((state) => state.settings.selected_theme) || 'light';
  const dropdownRef = useRef(null)
  //const [startItem, setStartItem] = useState(0);
  const [showConfirm, setShowConfirm] = useState(false);
  const [botSelected,setBotSelected] = useState(null)
  const [botDetailView,setBotDetailView] = useState(null)
  const [openMenu, setOpenMenu] = useState(false);
  const strategy = rental.template.strategy
  const history = useHistory()
  const thumbsUp="icon-like"
  const thumbsActive="icon-liked"
  const isWaiting=(rental)=>{
    return rental.status =='waiting';
  }
  const handleClickOutside = (event) => {
    // Check if the click is outside of any bot's menu or button
    if (!dropdownRef.current || dropdownRef.current.contains(event.target)) {
      return;
    }
    else{
      setOpenMenu(false)
    }
  };
  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);
  const dispatch = useDispatch();
  const clickCreateBot = (rental)=>{
    console.log(rental,"rental")
    if(isWaiting(rental)){
      return false
    }
    // if(rental.can_create_bot==0){
    //   toast.error(t("max-bots-reached"))
    //   return false
    // }
    resetRentBotConfig(rental);
    dispatch({
      type: SET_RENT_BOT_SELECTED,
      payload: rental,
    });
    dispatch({
      type: CREATE_BOT_START,
      payload: true,
    });
    setBotSelected(rental)
    history.push('/rent/create')
    //setShowCreateBotRentModal(true)
  }
  const clickThumbsUp = (e,val,id)=>{
    e.target.className= val==0?thumbsActive:thumbsUp
    botFeedback(val,id,selectedExchangeId,itemsPerPage,pageNum)
  }
  const showRentBotDetail = (rental)=>{
    setLoading(true);
    getRentBotDetail(rental.id).then(()=>{
      setBotSelected(rental)
      setBotDetailView(true)
      setLoading(false);
    })
    
  }
  const clickDeleteRental = ()=>{
    setLoading(true)
    cancelRental(rental.id).then(()=>{
      setLoading(false)
      window.location.reload();
      toast.success("Subscription Cancelled!");
    }).catch((err)=>{
      setLoading(false)
      toast.error(err);
    })
  }

  
  return (
    <Loading {...bindLoading}>
      <div className="col-xl-3 col-lg-4 col-6">
										<div className="dashboard-rented-cart-content">
											<div className="dashboard-rented-cart-header">
												<h5 className="dna-green-title">{rental.template.title}</h5>
												<div className="dropdown dr-cart-dropdown" ref={dropdownRef}>
													<i className="fa-solid fa-ellipsis-vertical menu-dots" id="dropdownMenuButton1" data-bs-toggle="dropdown" aria-expanded="false" onClick={()=>setOpenMenu(openMenu?false:true)}></i>
													<ul className={`dropdown-menu rental-menu ${openMenu?"show":""}`} aria-labelledby="dropdownMenuButton1">
														<li>
															<a className="dropdown-item" href="#" onClick={()=>clickCreateBot(rental)}>
                                <div><img src="/images/icons/plus-icon.png" alt=""/></div>
                                <div>Create from <br/>
																this rental</div>
															</a>
														</li>
														<li>
															<a className={`dropdown-item pointer ${rental.thumbs_up==0?'':'favourite'}`}>
                                <div><img src={`/images/icons/${rental.thumbs_up==0?thumbsUp:thumbsActive}.png`} className={`${rental.thumbs_up==0?thumbsUp:thumbsActive} like`} onClick={(e)=>clickThumbsUp(e,rental.thumbs_up,rental.template.id)} alt=""/></div>
                                <div>Favorite</div>
															</a>
														</li>
														<li>
                            {isWaiting(rental)?<>
                              <a className="dropdown-item pointer">
                                <div><img  src="/images/drdp-icon3.png" alt=""/></div>
                                <div>{t("waiting-for-payment")}</div>
															</a>
                            </>:
                            <>
                            <a className="dropdown-item pointer" onClick={()=>showRentBotDetail(rental)}>
                                <div><img  src="/images/drdp-icon3.png" alt=""/></div>
                                <div>Detail</div>
															</a>
                            </>}
															
														</li>
														<li>
															<a className="dropdown-item pointer"  onClick={()=>setShowConfirm(true)}>
                                <div><img  src="/images/drdp-icon4.png" alt=""/></div>
                                <div>Cancel</div>
															</a>
														</li>
													</ul>
												</div>
											</div>
											<div className="dashboard-rented-cart-body">
												<div className="dr-cart-btc-left">
													<span>W/L Ratio</span>
													<p>{rental.template.wl_ratio} %</p>
												</div>
												<div className="dr-cart-btc-right">
													<span>Performance</span>
													<p>{rental.template.performance}%</p>
												</div>
											</div>
											<div className="dr-cart-footer">
												<a href="#" className={rental.template.apy>=0?"long":"short"}><span>APY</span>{rental.template.apy} %</a>
												<a href="#" className={strategy}>{strategy}</a>
											</div>
										</div>
									</div>
                  {botDetailView && <RentBotViewDetail bot={botSelected} onHide={()=>setBotDetailView(false)} t={t}></RentBotViewDetail> }
                  {showConfirm && <CancelRentalConfirm onHide={()=>setShowConfirm(false)} onConfirm={clickDeleteRental} bot={rental}></CancelRentalConfirm> }
      <Loading  {...bindLoading} />
                      
    </Loading>
    
  );
};

export default connect(null, { activeBot,resetRentBotConfig,getRentBotDetail,botFeedback,cancelRental  })(RentCard);
const ConfirmDialog = (props) => {
  const clickClose = () => {
    props.onHide();
  };
  const clickCancel = () => props.onHide();
  return (
    <Modal show={props.show} onHide={props.onHide}>
      <Modal.Header>
        <Modal.Title>Confirm Dialog</Modal.Title>
      </Modal.Header>
      <Modal.Body>Please confirm that you would like to delete this deal by clicking the Delete button below.</Modal.Body>
      <Modal.Footer>
        <Button variant="danger" onClick={props.delete}>
          Delete
        </Button>
        <Button variant="info" onClick={clickCancel}>
          Cancel
        </Button>
      </Modal.Footer>
    </Modal>
  );
};
