import {
  GET_DEALS,
  GET_DEAL_TRADES,
  DEAL_DETAIL,
  CLOSE_DEAL_SUCCESS,
  CLOSE_DEAL_ERROR,
  START_CLOSE_DEAL,
  RESET_DEAL_DETAIL,
} from "./types";
import {
  getDealsUrl,
  dealDetailUrl,
  closeDealUrl,
  deleteDealUrl,
  getDealTradesUrl,
  refreshDealUrl
} from "./const";
import network from "./network";

export const getDeals = (exchangeId,pageSize,pageNum,status,botId) => (dispatch, getState) => {
  const params = {
    exchange_id: exchangeId,
    page_size: pageSize,
    page: pageNum,
    status: status,
    bot_id:botId
  };

  network.get(getDealsUrl, params, dispatch, getState).then((res) => {

    dispatch({
      type: GET_DEALS,
      payload: {
        deals:res.data.deals?pageSize?res.data.deals.data:res.data.deals:[],
        totalDeals:res.data.deals?pageSize?res.data.deals.total:0:0,
        startItem:res.data.deals?pageSize?res.data.deals.from:1:1
      },
    });
  });
};

export const getDealDetail = (id) => (dispatch, getState) => {
  network
    .get(
      dealDetailUrl,
      {
        id,
      },
      dispatch,
      getState
    )
    .then((res) => {
      const { deal } = res.data;
      dispatch({
        type: DEAL_DETAIL,
        payload: deal,
      });
    });
};

export const closeDeal = (id,exchangeId,pageSize,pageNum,status) => (dispatch, getState) => {
  dispatch({
    type: START_CLOSE_DEAL,
  });

  network
    .post(
      closeDealUrl,
      {
        id,
        status: "cancelled",
        force: 1,
      },
      dispatch,
      getState
    )
    .then((res) => {
      const { code, message } = res.data;
      if (code === 200) {
        dispatch({
          type: CLOSE_DEAL_SUCCESS,
        });
        dispatch(getDeals(exchangeId,pageSize,pageNum,status));
      } else {
        dispatch({
          type: CLOSE_DEAL_ERROR,
          payload: message,
        });
      }
    })
    .catch((err) => {
      dispatch({
        type: CLOSE_DEAL_ERROR,
        payload: String(err),
      });
    });
};

export const resetDealDetail = () => (dispatch) => {
  dispatch({
    type: RESET_DEAL_DETAIL,
  });
};

export const deleteDeal = (id,exchangeId,pageSize,pageNum,status) => (dispatch, getState) => {
  return new Promise((resolve, reject) => {
    network
      .post(
        deleteDealUrl,
        {
          id,
        },
        dispatch,
        getState
      )
      .then((res) => {
        const { code, message } = res.data;
        if (code === 200) {
          dispatch(getDeals(exchangeId,pageSize,pageNum,status));
          resolve();
        } else {
          reject(message);
        }
      })
      .catch((err) => {
        reject(String(err));
      });
  });
};
export const getDealTrades = (exchangeId,pageSize,pageNum,status,side,order) => (dispatch, getState) => {
  const params = {
    exchange_id: exchangeId,
    page_size: pageSize,
    page: pageNum,
    status: status,
    side: side,
    order_name: order
  };

  network.get(getDealTradesUrl, params, dispatch, getState).then((res) => {
    dispatch({
      type: GET_DEAL_TRADES,
      payload: {
        trade_list:res.data.trades.data,
        totalTrades:res.data.trades.total,
        startItem:res.data.trades.from
      },
    });
  });
};
export const refreshDealStatus = (id,exchangeId,pageSize,pageNum,status) => (dispatch, getState) => {
  return new Promise((resolve, reject) => {
    network
      .post(
        refreshDealUrl,
        {
          deal_id:id,
        },
        dispatch,
        getState
      )
      .then((res) => {
        const { code, message } = res.data;
        if (code === 200) {
          dispatch(getDeals(exchangeId,pageSize,pageNum,status));
          resolve(message);
        } else {
          reject(message);
        }
      })
      .catch((err) => {
        reject(String(err));
      });
    
  });
};