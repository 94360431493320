import React, { useState, useEffect, useRef } from "react";
import { Badge, Button, Modal, OverlayTrigger, Table, Tooltip } from "react-bootstrap";
import Switch from "react-switch";
import { connect,useSelector } from "react-redux";
import { activeBot, deleteBot } from "../../actions/bot-action";
import NoData from "../common/NoData";
import "./BotTab.scss";
import { capitalize, currencyFormat, decimalOf, isLong, trimString } from "../../utils/Utils";
import { toast } from "react-toastify";
import { t } from "i18next";
import { Link } from "react-router-dom/cjs/react-router-dom";
import useLoading from "../hooks/useLoading";
import Loading from "../common/Loading";
import CustomSwitch from "./new-bot/modules/CustomSwitch";
import ToolTip from "../help/ToolTip";

const BotCard = (props) => {
  
  const { bot,itemsPerPage,pageNum,selectedTab,t,clickBotAction,deleteBot} = props;
  const theme =  useSelector((state) => state.settings.selected_theme) || 'light';
  const sort = useSelector((state) => state.pagination.botSort) || "apy";
  const orderBy = useSelector((state) => state.pagination.botsOrderBy) || "desc";
  const dropdownRef = useRef(null)
  const selectedExchangeId =
    useSelector((state) => state.settings.selectedExchangeId) || 0;
  const [setLoading, bindLoading] = useLoading(false);
  const [showConfirm, setShowConfirm] = useState(false);
  const [statusList, setStatusList] = useState();
  const [botStatus, setBotStatus] = useState(bot.status);
  const [openMenu, setOpenMenu] = useState(false);
  const fiatBalance = useSelector((state) => state.dashboard.assets.balance_fiat);
  const pair = bot.exchange_pair.pair;
  const strategy = bot.strategy;
  const baseCoin = strategy.toLowerCase() == "long" ? pair.to : pair.from;
  const handleClickOutside = (event) => {
    // Check if the click is outside of any bot's menu or button
    if (!dropdownRef.current || dropdownRef.current.contains(event.target)) {
      return;
    }
    else{
      setOpenMenu(false)
    }
  };
  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);
  const confirmDelete = () => {
    setLoading(true);
    deleteBot(bot.id,selectedExchangeId,itemsPerPage,pageNum,selectedTab)
      .then(() => {
        setLoading(false);
        setOpenMenu(false)
        setShowConfirm(false)
        toast.success(t("bot-deleted-msg"));
      })
      .catch((err) => {
        setLoading(false);
        toast.error(err);
        setShowConfirm(false)
      });
  };
  const clickDelete = () => {
    setShowConfirm(true)
  };
  const updateStatus = (checked)=>{
    setBotStatus(botStatus=='active'?'inactive':'active')
    props
      .activeBot(bot.id, checked ? "active" : "inactive",selectedExchangeId,itemsPerPage,pageNum,selectedTab,orderBy,sort)
      .then((res) => {
        toast.success(res.message);
      })
      .catch((err) => {
        setBotStatus(botStatus=='active'?'inactive':'active')
        toast.error(err);
      });
  }
  //const [startItem, setStartItem] = useState(0);

  
  return (
    <Loading {...bindLoading}>
    <div className="col-xl-4 col-lg-4 col-md-4 col-6" key={bot.id}>
												<div className="main-dna-cart">
													<div className="dna-cart-header" >
														<ul>
															<li>
																<img src={pair.base_image} className="bot-list-base-img-icon" alt="icon"/>
																<h6>{pair.from}/{pair.to}</h6>
															</li>
															<li>
																{/* <i className="fa-solid fa-heart heart"></i> */}
																<div className="dropdown" ref={dropdownRef}>
																	<i className="fa-solid fa-ellipsis-vertical menu-dots" id="dropdownMenuButton1" data-bs-toggle="dropdown" aria-expanded="false" onClick={()=>setOpenMenu(openMenu?false:true)}></i>
																	<ul className={`dropdown-menu ${openMenu?"show":""}  dna-cart-dropdown`} aria-labelledby="dropdownMenuButton1">
																	  	<li>
                                      <Link to={`/bot-detail/${bot.id}`} className="dropdown-item">	<img src="images/c-details-icon.png" alt=""/>
																				Details</Link>
																		</li>
																	  	<li>
																			<a className="dropdown-item" href="#" onClick={()=>clickBotAction('edit')}>
																				<img src="images/c-edit-icon.png" alt=""/>
																				Edit
																			</a>
																		</li>
																		<li>
																			<a className="dropdown-item" href="#" onClick={!bot.rent_id?()=>clickBotAction('copy'):void(null)}>
																				<img src="images/c-copy-icon.png" alt=""/>
																				Copy
																			</a>
																		</li>
																		{/* <li>
																			<a className="dropdown-item" href="#">
																				<img src="images/c-copy-icon.png" alt=""/>
																				Copy to Broker
																			</a>
																		</li> */}
																		<li>
																			<a className="dropdown-item pointer " onClick={clickDelete}>
																				<img src="images/c-delete-icon.png" alt="" />
																				Delete
																			</a>
																		</li>
																		<div className="dna-cart-dropdown-footer">
																			<span>{bot.status && capitalize(bot.status)}</span>
																			<div className="check-box">
                                        <CustomSwitch checked={botStatus=='active' || false} onChange={(checked) => updateStatus(checked)}></CustomSwitch>
																			</div>
																		</div>
																	</ul>
																</div>
															</li>
														</ul>
													</div>
													<div className="dna-cart-body">
														<h4 className={`bot-${bot.statusVariant}`}>{bot.name}{bot.notice != "" &&  <OverlayTrigger
      placement="top"
      overlay={<Tooltip>{bot.notice}</Tooltip>}
    ><i className="fa fa-exclamation-circle deativate-warning" aria-hidden="true"></i></OverlayTrigger> }</h4>
														<div className="dna-cart-progress">
															<ul>
																<li>Allocated Fund</li>
                                {isLong(bot.strategy)?
                                  <><li><b>{currencyFormat(bot.allocated_fund,0,pair.to)} / <span>{currencyFormat(bot.initial_fund,0,pair.to)}</span> {pair.to}</b></li>
                                  </>
                                  :
                                  <>
                                  <li><b>{currencyFormat(bot.allocated_fund,4,pair.from)} / <span>{currencyFormat(bot.initial_fund,4)}</span> {pair.from}</b></li>
                                  </>
                                 }
																
															</ul>
															<div className="progress">
																<div className="progress-bar" role="progressbar" style={{width: (bot.initial_fund && (bot.allocated_fund/bot.initial_fund)*100)+'%'}} aria-valuenow="60" aria-valuemin="0" aria-valuemax="100"></div>
															</div>
														</div>
														<div className="dna-cart-btc">
															<div className="dna-cart-btc-left">
																<span>Return</span>
																<p>{parseFloat(bot.deals_analysis.total_performance).toFixed(
                                    decimalOf(baseCoin)
                                  ) || "0"}{" "}
                                  {baseCoin}</p>
															</div>
															<div className="dna-cart-btc-right">
																<span>Performance</span>
                                  <p>{parseFloat(bot.deals_analysis.return_value_avg).toFixed(2) || "0"}%</p>
															</div>
														</div>
													</div>
													<div className="dna-footer">
														<a href="#" className={bot.deals_analysis.apy>=0?"long":"short"}><span>APY</span>{bot.deals_analysis.apy}%</a>
														{bot.rent_id?<ToolTip content={bot.template_name}><a href="#" className="strategy_name">{bot.template_name && trimString(bot.template_name,14)}</a></ToolTip>:<></>}
														<a className={isLong(bot.strategy) ? "long" : "short"}>{bot.strategy}</a>
													</div>
												</div>
											</div>
                      <ConfirmDialog show={showConfirm} onHide={() => setShowConfirm(false)} delete={confirmDelete}/>
    </Loading>
    
  );
};

export default connect(null, { activeBot,deleteBot })(BotCard);
const ConfirmDialog = (props) => {
  const clickClose = () => {
    props.onHide();
  };
  const clickCancel = () => props.onHide();
  return (
    <Modal show={props.show} onHide={props.onHide}>
      <Modal.Header>
        <Modal.Title>Confirm Dialog</Modal.Title>
      </Modal.Header>
      <Modal.Body>Please confirm that you would like to delete this bot by clicking the Delete button below.</Modal.Body>
      <Modal.Footer>
        <button className="blue-button" onClick={props.delete}>
          Delete
        </button>
        <button className="grey-button" onClick={clickCancel}>
          Cancel
        </button>
      </Modal.Footer>
    </Modal>
  );
};
