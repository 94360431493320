import React, { useEffect, useState } from "react";
import { Col, Dropdown, DropdownButton } from "react-bootstrap";
import { connect, useDispatch, useSelector } from "react-redux";
import Loading from "../common/Loading";
import BotTab from "./BotCard";
import BotTabRent from "./BotTabRent";
import useLoading from "../hooks/useLoading";
import { getBotDetail, getBots, getIndicators, getRentalBots } from "../../actions/bot-action";
import { resetBotConfig, updateCopyBotConfig, updateEditBotConfig, updateEditRentBotConfig } from "../../actions/create-bot-action";
import { useHistory, useLocation } from "react-router-dom";
import { CREATE_BOT_START } from "../../actions/create-bot-type";
import queryString from "query-string";
import ReactStatePagination from "../common/ReactStatePagination";
import { BOTS_ACTIVE_TAB, BOTS_ORDER_BY, BOTS_SORT } from "../../actions/types";
import { toast } from "react-toastify";
import BotCard from "./BotCard";
import RentCard from "./RentCard";
import RentedListEmpty from "./RentedListEmpty";


const RentedList  = (props)=>{
    const {t,getRentalBots,resetBotConfig,searchBotName} = props
   
    
   
    const [setLoading, bindLoading] = useLoading(false);
    const history = useHistory()
    const dispatch = useDispatch()
    var rents = useSelector((state) => state.bots.rents) || [];
    const totalRentBots = useSelector((state) => state.bots.totalRentBots) || 0;
    const startItemRent = useSelector((state) => state.bots.startItemRent) || 1;
    const selectedExchangeId = useSelector((state) => state.settings.selectedExchangeId) || 0;
    const status = useSelector((state) => state.pagination.botsActiveTab) || "all";
    const sort = useSelector((state) => state.pagination.botSort) || "name";
    const orderBy = useSelector((state) => state.pagination.botsOrderBy) || "desc";
    const pageNumbers = {
      "active":useSelector((state) => state.pagination.rentedStrategies)
    }
    const itemsPerPage = 12;
    const paginationCallbackRent = (pageNum)=>{
      getRentalBots(selectedExchangeId,itemsPerPage,pageNum,status,orderBy,sort,searchBotName)
    }

    
    useEffect(() => {
      getRentalBots(selectedExchangeId,itemsPerPage,pageNumbers[status],'',orderBy,sort,searchBotName);
      resetBotConfig();
    }, [status,sort,orderBy,selectedExchangeId,searchBotName]);
    
    const rows = rents
    //.slice(startItem, startItem + itemsPerPage)
    .map((rent, index) => {
      return (
        <RentCard rental={rent} key={index}></RentCard>
      );
    });
    return(
        <>
        {totalRentBots > 0?<>
          {rows}
       
       <div className="bottom-center">
       <ReactStatePagination
           itemsCountPerPage={itemsPerPage}
           forPage="rents"
           selectedExchangeId={selectedExchangeId}
           startItem={startItemRent}
           selectedTab="active"
           totalCount={totalRentBots}
           onChange={paginationCallbackRent}
           itemsPerPage={itemsPerPage}
         />
       </div>
        </>:<><RentedListEmpty></RentedListEmpty>
        </>}
        
        
        </>
    )
}

export default connect(null,{getBots,getRentalBots,getBotDetail,updateCopyBotConfig,updateEditBotConfig,resetBotConfig,getIndicators,updateEditRentBotConfig}) (RentedList);