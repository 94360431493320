import React, { useEffect, useState } from "react";
import { connect, useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { Col, Row } from "react-bootstrap";
import Tab1 from "./quickstart/Tab1";
import Tab2 from "./quickstart/Tab2";
import Tab3 from "./quickstart/Tab3";
import Tab4 from "./quickstart/Tab4";
import { resetBotConfig, setupSimpleBot } from "../../actions/create-bot-action";
import { BotType } from "../../Const";
import { NEW_USER_INTRO_POPUP } from "../../actions/types";
import { hideIntroPopup } from "../../actions/setting-action";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import { isPremium } from "../../utils/SubUtils";
const WelcomeWizard = (props) => {
  const {setupSimpleBot,hideIntroPopup} = props
  
  const { t } = useTranslation()
  const dispatch = useDispatch()
  useEffect(() => {
      resetBotConfig();
      setupSimpleBot()
  }, []);
 const [activeTab,setActiveTab] = useState(0);
 const goNext = ()=>{
    setActiveTab(activeTab+1)
 }
 const goPrev = ()=>{
    setActiveTab(activeTab-1)
 }
 const history = useHistory();
 const exitWizard = ()=>{
  history.push("/bots");
  dispatch({
    type:NEW_USER_INTRO_POPUP,
    payload:0
  })
  hideIntroPopup(0)
}

  
  const tabHeaders = Array.from({length:4,start:1},(_,index)=>{
    return(
      <li key={index} className={`${activeTab==index?'current ':'' }${activeTab>=index?'active':'' }`}><a className={`${activeTab>=index?'active':'' }`}><img src={`/images/qs/qs_${index+1}${activeTab>=index?'_active.svg':'.svg' }`} /></a></li>
    )
       
  })
  const rows = [
    <Tab1 next={goNext} prev={goPrev}></Tab1>,
    <Tab2 next={goNext} prev={goPrev}></Tab2>,
    <Tab3 next={goNext} prev={goPrev}></Tab3>,
    <Tab4 next={goNext} prev={goPrev}></Tab4>
  ]
    
  return (
    <div className="quickstart-timeline-area">
								<div className={`quickstart-timeline-left`}>
                  <ul>
                     {tabHeaders}
                    </ul>
                </div>
                <div className="quickstart-timeline-right">
                {rows[activeTab]}
                <div className="text-right">
                <h7 className="pointer" onClick={()=>exitWizard()}> <i className="fa-sharp-duotone fa-solid fa-xmark"></i> &nbsp; Exit wizard and don't show this again</h7>
               
                </div>
                
                </div>
      </div>
                  
    
     
  );
};

export default connect(null,{setupSimpleBot,hideIntroPopup})(WelcomeWizard);
