import React, { useEffect, useState } from "react";
import { connect, useSelector, useDispatch } from "react-redux";
import {
  getBots,
  getIndicators,
  getRentalBots,
  getSubscription
} from "../../actions/bot-action";

import "./Bots.scss";

import ContentContainer from "../common/ContentContainer";

import { getBotDetail } from "../../actions/bot-action";

import {
  resetBotConfig,
  updateEditBotConfig,
  updatePresetConfig,
  updateCopyBotConfig,
  updateEditRentBotConfig
} from "../../actions/create-bot-action";
import { getDashboardAssets } from "../../actions/dashboard-action";

import { useTranslation } from "react-i18next";
import BotList from "./BotList";
import BotPageOverview from "./BotPageOverview";
import BotFilterPanel from "./BotFilterPanel";
import { capitalize, currencyFormat } from "../../utils/Utils";
import BotTypeList from "./BotTypeList";
import BalanceInfo from "./BalanceInfo";
import WelcomeWizard from "../tour/WelcomeWizard";
import RentedList from "./RentedList";
import RentalFilterPanel from "./RentalFilterPanel";

const Rentals = (props) => {
  const {
    getSubscription,
	getDashboardAssets
  } = props;
  const { t } = useTranslation()
  const selectedExchangeId =
    useSelector((state) => state.settings.selectedExchangeId) || 0;
  const fiatBalance = useSelector((state) => state.dashboard.assets.balance_fiat);
  const status = useSelector((state) => state.pagination.botsActiveTab) || "all";
  const newUserIntroPupup = useSelector((state) => state.settings.newUserIntroPupup);
  const [searchBotName, setSearchBotName] = useState("");

  useEffect(() => {
    getSubscription();
	getDashboardAssets(selectedExchangeId)
  getRentalBots(selectedExchangeId,12,1,'','','',searchBotName);
  }, [selectedExchangeId]);

  return (
      <ContentContainer url="bots" title={t("bots")} showChat={true}>
        <div className="dashboard-newuser-area">
              <BalanceInfo></BalanceInfo>
              <div className="deshboard-normal-allBots-area">
								<div className="deshboard-normal-allBots-header">
									<h3>My Rented Strategies</h3>
                  <div className="deshboard-normal-allBots-header-right">
										<RentalFilterPanel status={status} searchBotName={searchBotName} setSearchBotName={setSearchBotName}></RentalFilterPanel>
									</div>
								</div>
								<div className="deshboard-normal-allBots-body">
									
									<div className="dna-cart-content">
										<div className="row g-3">
											<RentedList t={t} searchBotName={searchBotName}></RentedList>
										</div>
									</div>
									
								</div>
							</div>
            </div>
      
      </ContentContainer>
  );
};

export default connect(null, {
  getBots,
  getSubscription,
  updateEditBotConfig,
  updateEditRentBotConfig,
  resetBotConfig,
  updatePresetConfig,
  getIndicators,
  updateCopyBotConfig,
  getBotDetail,
  getRentalBots,
  getDashboardAssets
})(Rentals);
