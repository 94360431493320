import React, { useState, useEffect } from "react";
import { connect, useSelector } from "react-redux";
import { useLocation, Redirect, Link } from "react-router-dom";
import {
  signIn,
  resetSignIn,
  signInGoogle,
  signInFacebook,
  signInApple,
  getRegisterOtp,
  postAppSumoCode,
} from "../../actions/auth-action";
import { changeTimezone } from "../../actions/setting-action";
import {
  Container,
  Row,
  Col,
  Form,
  Button,
  Alert,
  Card,
} from "react-bootstrap";
import { GoogleLogin } from "react-google-login";
import FacebookLogin from "react-facebook-login/dist/facebook-login-render-props";
import AppleLogin from "react-apple-login";
import "./Login.scss";
import { getCurrentTimeZone } from "../../utils/Utils";
import Header from "../common/HeaderGuest";
import Footer from "../common/Footer";
import queryString from "query-string";
import { t } from "i18next";
import { useTranslation } from "react-i18next";
import GoogleLoginComponent from "./GoogleLoginComponent";
import HeaderGuest from "../common/HeaderGuest";
import GuestContainer from "./GuestContainer";

const Login = (props) => {
  const {
    resetSignIn,
    signIn,
    signInGoogle,
    signInFacebook,
    signInApple,
    getData,
    postAppSumoCode
  } = props;
  const location = useLocation();
  const host = window.location.origin;
  const hash = window.location.hash;
  const token = useSelector((state) => state.auth.token);
  const user = useSelector((state) => state.auth.user);
  const errorMessage = useSelector((state) => state.auth.errorMessage);
  const signInStart = useSelector((state) => state.auth.signInStart);
  const { search } = useLocation();
  const { t } = useTranslation();
  const query = queryString.parse(search);
  const pathname = "/bots";
  const gaConfirmed = useSelector((state) => state.auth.gaConfirmed);
  const gaEnabled = useSelector((state) => state.settings.gaEnabled);
  const [appSumoCode, setAppSumoCode] = useState(query.app_sumo_code || "");
  const [referralCode, bindReferralCode] = useState(query.ref || "");

  const [email, setEmail] = useState("");
  const [pass, setPass] = useState("");
  const clientId = '189770389469-obki6gv1ppkhfvjjdsgeo8u4d5f0aqb9.apps.googleusercontent.com';


  useEffect(() => {
    resetSignIn();
    return () => resetSignIn();
  }, []);
  

  useEffect(() => {
    const currentTimezone = getCurrentTimeZone();
    window.timezone = currentTimezone;
  }, []);

  const login = (e) => {
    e.preventDefault();
    signIn(email, pass,appSumoCode?{app_sumo_code:appSumoCode}:false);
  };
  // const sendAppSumoCode = () => {
  //   postAppSumoCode(appSumoCode).then;
  // };

  const loginGoogle = (res) => {
    const { tokenId } = res;
    signInGoogle(tokenId);
  };

  const loginFacebook = (res) => {
    const { accessToken, userID } = res;
    signInFacebook(accessToken, userID,appSumoCode?{app_sumo_code:appSumoCode}:false);
  };

  const loginApple = (res) => {
    console.log("Apple", res);
  };

  const failedLoginGoogle = (res) => {
    console.log("Failed login Google", res);
  };

  useEffect(() => {
    if (hash) {
      const idTokenIndex = hash.indexOf("id_token");
      const idToken = hash.slice(idTokenIndex + 9);
      signInApple(idToken);
    }
  }, [hash]);

  if (token) {
    if(appSumoCode){
       postAppSumoCode(appSumoCode).then((res)=>{
        console.log(res)
      })
    }
    if (user && user.email_verified_at) {
      if (gaEnabled && !gaConfirmed) return <Redirect to="/ga/confirm" />;
      return <Redirect to={pathname} />;
    }
    return <Redirect to="/verify-otp" />;
  }

  return (
    <>
    <GuestContainer>

    <div className="login-content-wrapper">
          <div className="login-content">
              <a href="#"><img src="/images/named-logo.png" alt="logo"/></a>
              <h4 className="mt-3 header-text mb-2">{t("log-in-header")}</h4>
              <br></br>
              <form>
                <div className="pos-relative m-0-auto w-80">
                <img src="/images/icon-email.svg" className="input-icon"/>
                <input
                    type="email"
                    placeholder={t("email")}
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                    className=""
                  />
                </div>
                <div className="pos-relative m-0-auto w-80">
                <img src="/images/icon-lock.svg" className="input-icon"/>
                {/* <label className="pass-label"> */}
                <input
                    type="password"
                    placeholder={t("Password")}
                    value={pass}
                    onChange={(e) => setPass(e.target.value)}
                    className="form-input input-with-icon password-bg auth-input"
                    autocomplete="chrome-off"
                  />
                  {/* <span id="showPass">
                    <i className="fa-regular fa-eye-slash"></i>
                    <i className="fa-regular fa-eye" style={{display: 'none'}}></i>
                  </span> */}
                {/* </label> */}
                </div>
                
                {/* <div className="forgot-pass link-text">
                  
                </div> */}
                
                <button type="submit" onClick={login} className={"capitalize"}>
                  {signInStart ? "PROCESSING..." : t("login-now")}
                </button>
                
                <Alert
                  show={errorMessage != null}
                  variant="danger"
                  className="mt-2"
                >
                  {errorMessage && errorMessage}
                </Alert>
              </form>
              <span>OR LOGIN WITH</span>
              <ul>
                <li><FacebookLogin
                  appId="649699255566506"
                  fields="name,email,picture"
                  callback={loginFacebook}
                  render={(renderProp) => {
                    return (
                      <div className="circle text-center social-circle">
                        <a onClick={renderProp.onClick}>
                        <img src="/images/icons/login-fb.svg"/>
                        </a>
                      </div>
                    );
                  }}
                /></li>
                <li> <GoogleLoginComponent clientId={clientId} appSumoCode={appSumoCode} referralCode={referralCode}></GoogleLoginComponent></li>
                <li><AppleLogin
                        clientId="ventures.novum.stockhero"
                        redirectURI={`${host}/login`}
                        callback={loginApple}
                        responseType="code id_token"
                        responseMode="fragment"
                        render={(renderProp) => {
                          return (
                            <div className="circle text-center social-circle">
                              <a onClick={renderProp.onClick}>
                                <img src="/images/icons/Apple.svg"/>
                              </a>
                            </div>
                          );
                        }}
                      /></li>
              </ul>
          </div>
          <div className="login-content-bottom-link">
                <ul>
                  <li><Link to="/forget-pass">{t("f-pass")}</Link></li>
                  <li>{t("no-acc")} {appSumoCode?<><Link to={`/signup?app_sumo_code=${appSumoCode}`} className="link-text">{t("create-acc")}</Link></>:<><Link to={`/signup`} className="link-text">{t("create-acc")}</Link></>}</li>
                  
                </ul>
              </div>
        </div>
    </GuestContainer>
      
    </>
  );
};
export default connect(null, {
  signIn,
  resetSignIn,
  signInGoogle,
  signInFacebook,
  signInApple,
  changeTimezone,
  getRegisterOtp,
  postAppSumoCode
})(Login);
