import React, { useEffect } from "react";
import { connect, useSelector, useDispatch } from "react-redux";
import { Card, Accordion, Form, InputGroup } from "react-bootstrap";

import TutorialPopup from "../../help/TutorialPopup";
import * as HelpContent from "../../../utils/HelpContent";
import { CHANGE_LEVERAGE_RENT, CHANGE_RENT_FUND } from "../../../actions/create-bot-type";
import { isLong } from "../../../utils/Utils";
import { formatNumberToString } from "../../../utils/Utils";
import LeverageSlider from "../../bots/new-bot/LeverageSlider";
import MaxPositionSize from "../../bots/new-bot/MaxPositionSize";
import MarginModeModule from "../../bots/new-bot/futures/modules/MarginModeModule";
import OrderTypeModule from "../../bots/new-bot/futures/modules/OrderTypeModule";
import PriceTypeModule from "../../bots/new-bot/futures/modules/PriceTypeModule";
import SliderView from "../../bots/new-bot/SliderView";
import { BotType } from "../../../Const";
import CreateRentBotSection from "../../bots/new-bot/modules/CreateRentBotSection";

const TradeParamsViewRentFutures = (props) => {
  const { editing,t,step } = props;
  const rental = useSelector((state) => state.createBot.rentBot.rental);
  const rentBot = useSelector((state) => state.createBot.rentBot);
  const fund = rentBot.initFund;
  const maxLeverage = rentBot.botType == BotType.simple?5:125;
  const formartLeverage = (value) => value+"x";
  const currentCoin = isLong(editing?rentBot.strategy:rental.template.strategy)
    ? rentBot.quoteCoin
    : rentBot.baseCoin;
  const balances = useSelector((state) => state.bots.usdMBalances);
  const balance =
    balances && balances.find((item) => item.coin === currentCoin);
  const dispatch = useDispatch();
  useEffect(() => {
    if (balance && !editing) {
      //fundChanged(balance.free);
    }
  }, [balance]);

  const fundChanged = (val) => {
    dispatch({
      type: CHANGE_RENT_FUND,
      payload: val,
    });
  };
  const leverageChanged = (val) => {
    dispatch({
      type: CHANGE_LEVERAGE_RENT,
      payload: val,
    });
  };

  return (
    <>
       <div className="ba-asset-form-content">
       <div className="row gx-0 gy-3">
            <SliderView
                title={t("leverage")}
                step={1}
                min={1}
                max={maxLeverage}
                formatLabel={formartLeverage}
                onChange={leverageChanged}
                value={rentBot.leverage}
                tutorial={<TutorialPopup content={t("leverage-help")} />}
            />
            <div style={{ marginTop: 20 }}>
            <MaxPositionSize 
                newBot={rentBot} 
                editing={editing} 
                fund={fund} 
                currentCoin={currentCoin} 
                balance={balance}
                rent={true}
                t={t}>
            </MaxPositionSize>
            </div>
            <div style={{ marginTop: 20 }}>
                <MarginModeModule 
                newBot={rentBot} 
                editing={editing} 
                rent={true}
                t={t}></MarginModeModule>
            </div>
            <div style={{ marginTop: 20 }}>
                <OrderTypeModule 
                newBot={rentBot} 
                editing={editing} 
                rent={true} 
                t={t}></OrderTypeModule>
            </div>
            <div style={{ marginTop: 20 }}>
                <PriceTypeModule 
                newBot={rentBot} 
                editing={editing} 
                rent={true}
                t={t}></PriceTypeModule>
            </div>
             <div className="col-lg-12">
                <br></br>
                <br></br>
                  <div className="bas-asset-submit bas-asset-btn2 bas-asset-btn3">
                  <button onClick={()=>step('prev')}>Previous</button>
                  <CreateRentBotSection rentBot={rentBot}></CreateRentBotSection>
                </div>
              </div>
         </div>
         </div>
    </>
  );
};

export default connect(null)(TradeParamsViewRentFutures);
