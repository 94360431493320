import React, { useRef, useState } from "react";
import { useOnHoverOutside } from "../../utils/useOnHoverOutside";
import { BotType, BotTypeName, SUB_TIER } from "../../Const";
import { checkSub, isMaster, isPremium, isPro } from "../../utils/SubUtils";
import { useDispatch, useSelector } from "react-redux";
import { Link, useHistory } from "react-router-dom/cjs/react-router-dom.min";
import { CHANGE_BOT_TYPE, CREATE_BOT_START } from "../../actions/create-bot-type";

const BotTypeList = (props)=>{
    const dropdownRef = useRef(null)
    const [isMenuDropDownOpen, setMenuDropDownOpen] = useState(false);
    const subscription =
    useSelector((state) => state.auth.user.user_subscription) || {};
    const isPaid = isPremium(subscription);
    const exchangeId =
    useSelector((state) => state.settings.selectedExchangeId) || 0;
    const isFuture = useSelector((state) => state.settings.isFuture);
    const { subPremium, subTier } = checkSub(subscription);
    const closeHoverMenu = () => {
        setMenuDropDownOpen(false);
    };
    const dispatch = useDispatch();
    const history = useHistory();
    const clickCreateBot = (botType,item) => {
      const {pro,master,isArbitrage,disabled,disabledItems} = checkEligible(item)
     if(disabledItems || disabled)
     return false
      dispatch({
        type: CHANGE_BOT_TYPE,
        payload: botType ?? BotType.simple,
      });
      dispatch({
        type: CREATE_BOT_START,
        payload: true,
      });
      history.push('/bots/create')

    };
    const checkEligible = (item)=>{
      //const disabled = BotType.simple !== BotType[item] && !isPaid;
      const disabled =  !isPaid;
      const pro = isPro(subscription)
      const master = isMaster(subscription)
      const isArbitrage = BotType.inter_arbitrage === BotType[item] || BotType.intra_arbitrage === BotType[item]
      const disabledItems =  (BotType.grid==BotType[item] && !(pro || master)) || (isArbitrage && exchangeId==0) || (isArbitrage && isFuture)
      return {pro,master,disabled,isArbitrage,disabledItems}
  }
    useOnHoverOutside(dropdownRef, closeHoverMenu); // Call the hook

   
    const botItems = Object.keys(BotType).map((item, id) => {
      const {pro,master,isArbitrage,disabled,disabledItems} = checkEligible(item)
      //const disabledItems = false;
      //const disabled = BotType.simple !== BotType[item] && !isPaid;
      
      return (
        <li key={id} disabled={disabledItems?true:disabled} className="bot-types pointer bot-type-li" onClick={()=>clickCreateBot(BotType[item],item)}>
          <a href="#">{BotTypeName[item]} {(disabled && (BotType.grid !=BotType[item])) && `(Premium)`} {((BotType.grid ==BotType[item]) && !(pro) ) && `(Pro Plan)`}</a>
        </li>
      );
    });
    return(
      <div className="dropdown createBot-dropdown" ref={dropdownRef}>
      <button className="dropdown-toggle show" type="button" id="dropdownMenu2" data-bs-toggle="dropdown" aria-expanded="false" onMouseOver={() => setMenuDropDownOpen(true)}>
        Create New Bot
      </button>
      <ul className={`dropdown-menu ${isMenuDropDownOpen?"show":""}`} aria-labelledby="dropdownMenu2">
        <li>
        <button className="dropdown-item" type="button">Create my own bot <i className="fa-solid fa-angle-right"></i></button>
        <ul>
          {botItems}
      </ul>
      </li>
        <li><Link to="/rented-strategies"><button className="dropdown-item" type="button"> Create from my <br/> rented strategies</button></Link></li>
      </ul>
</div>
    )
}

export default BotTypeList;