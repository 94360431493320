import React, { useEffect } from "react";
import { Form, InputGroup } from "react-bootstrap";
import { useDispatch } from "react-redux";
import { CHANGE_FUND, CHANGE_LEVERAGE, CHANGE_RENT_FUND, PER_ORDER_AMOUNT } from "../../../actions/create-bot-type";
import { BotType } from "../../../Const";
import { formatNumberToString, restrictAlphabets } from "../../../utils/Utils";
import SliderView from "./SliderView";
import LeftRightForm from "./modules/LeftRightForm";

const MaxPositionSize = (props)=>{
    const {t,newBot,editing,fund,currentCoin,balance,rent,calculateFundRequired,buySideFundReq} = props;
    const dispatch = useDispatch()
    useEffect(() => {
        if (balance && !editing) {
          //fundChanged(balance.free);
        }
      }, [balance,currentCoin]);
    const fundChanged = (val) => {
      if(val < 0){
          dispatch({
          type: rent?CHANGE_RENT_FUND:(newBot.botType === BotType.grid)?PER_ORDER_AMOUNT:CHANGE_FUND,
          payload: 0,
          });
          return false
      }
      dispatch({
          type: rent?CHANGE_RENT_FUND:(newBot.botType === BotType.grid)?PER_ORDER_AMOUNT:CHANGE_FUND,
          payload: val,
      });
    };
    return(
    <>
    
      <LeftRightForm
      left={<><h6>{newBot.botType === BotType.grid?t("per-position-size"):t("max-pos-size")}</h6></>}
      right={<>
        <label data-text={currentCoin} className="input-gp-label">
            <Form.Control
                as="input"
                type="number"
                placeholder="1000"
                value={newBot.botType === BotType.grid?newBot.gridOrderVol:fund}
                onChange={(e) => {
                  fundChanged(e.target.value);
                }}
                onKeyPress={(e) => {
                  restrictAlphabets(e);
                }}
                className="input-text input-gp-text"
              ></Form.Control>
          </label>
          <Form.Label className="mt-2">
          {newBot.botType === BotType.grid ? <> {t("est-mar-cost")} : {(((fund/newBot.leverage) * newBot.gridOrderNum) * 2).toFixed(4)} {currentCoin}</>
          :<> {t("est-mar-cost")} : {((fund/newBot.leverage) ).toFixed(4)} {currentCoin}</>}
        </Form.Label>
          <p style={{ fontSize: 13, marginTop: 10 }}>
          
          {t("available")}: {formatNumberToString(balance && balance.free)}{" "}
          {currentCoin}
        </p>
      </>}
      ></LeftRightForm>
    </>
    )
}
export default MaxPositionSize

