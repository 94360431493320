import React from "react";
import { Button } from "react-bootstrap";
import { useHistory } from "react-router-dom";
import { PLAN_NAME, PLAN_TYPE, SUBSCRIPTION_PLAN, SUB_STATUS, SUB_TIER, SUB_TIER_NAME, SUB_TYPE_NAME } from "../../Const";
import { getMonthlyPrice, getYearlyPrice } from "../../utils/SubUtils";
import MasterPlan from "./MasterPlan";
import "./PremiumInfo.scss";
import PremiumPlan from "./PremiumPlan";
import ProPlan from "./ProPlan.js";

const PremiumInfo = (props) => {
  const {
    isYearly,
    readOnly,
    onUpgrade,
    subStatus,
    subPremium,
    subTier,
    tier,
    plan,
    t,
    className,
    subscriptionType
  } = props;
  const history = useHistory();

  // const clickUpgrade = () => {
  //   if (subStatus == SUB_STATUS.canceled) {
  //     onUpgrade && onUpgrade();
  //   } else if (
  //     !subPremium ||
  //     (subPremium && subTier === SUB_TIER.premium && tier === SUB_TIER.pro)
  //   ) {
  //     history.push(
  //       "/subscription?plan=" +
  //         (isYearly ? "yearly" : "monthly") +
  //         "&tier=" +
  //         tier
  //     );
  //   }
  // };
  const clickUpgrade = () => {
    history.push(
      "/subscription?plan=" +
        plan +
        "&tier=" +
        tier
    );
  };
 
  const getPlanInfo = (plan)=>{
    switch(plan){
      case "premium":
        return <PremiumPlan t={t}></PremiumPlan>
      case "pro":
        return <ProPlan t={t}></ProPlan>
      case "master":
        return <MasterPlan t={t}></MasterPlan>
    }
  }
  return (
    <div className="col-xl-4 col-md-6 no-padding premium_info_div">
      <div className={`sp-cart-content ${className}`}>
            <div className="sp-cart-header">
						    <span> {PLAN_NAME[tier]}</span>
                <h4><small>US$</small>{SUBSCRIPTION_PLAN[plan] && SUBSCRIPTION_PLAN[plan][tier] }</h4>
            </div>
            <div className="sp-cart-body">
                {getPlanInfo(tier)}
                {readOnly ? (
                  <></>
                ) : (
                    <a  onClick={clickUpgrade} >
                      {subPremium && subStatus == SUB_STATUS.active
                      ? subTier == tier
                        ? "CURRENT PLAN" : subTier=== SUB_TIER.master?"INCLUDED":(subTier=== SUB_TIER.pro?(tier==SUB_TIER.master?"UPGRADE":"INCLUDED"):"UPGRADE")
                        
                      : "SUBSCRIBE"}
                    </a>
                )}
            </div>
        </div>
      
    </div>
  );
};

export default PremiumInfo;
